
.testimonial-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.testimonial-header {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;

  @media screen and (min-width: 2000px) {
    font-size: 3.75rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 1.75rem;
  }

  span { 
    color: var(--secondary-color) !important;
  }
}

.testimonial-slider {
  width: 100%;
  overflow: hidden;
}

.testimonial-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.testimonial-item {
  min-width: 33.33%;
  padding: 1rem;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
}

.testimonial-content {
  background-color: var(--white-color);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

  p {
    font-size: 1.25rem;
    line-height: 2rem;
    color: var(--black-color);
    font-family: var(--font-base);
  }

  h4 {
    font-weight: 600;
    color: var(--secondary-color);
    margin-top: 2rem;
  }
}


@media screen and (max-width: 900px) {
  .testimonial-item {
    min-width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .testimonial-item {
    min-width: 100%;
  }
}


.testimonial-controls {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.testimonial-arrow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--white-color);
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  svg {
    width: 20px;
    height: 20px;
    color: var(--secondary-color);
  }

  &:hover {
    background-color: var(--secondary-color);

    svg {
      color: var(--white-color);
    }
  }
}

.testimonial-arrow.disabled {
  background-color: #e0e0e0; 
  cursor: not-allowed;
  pointer-events: none;
}

.testimonial-arrow.disabled svg {
  color: #b0b0b0; 
}



