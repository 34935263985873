.about-me-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 100%;

    @media screen and (max-width: 768px) {
        flex-direction: column; 
      }
  
    .about-me-left {
      flex: 1;
        
      .image-container {
        text-align: center;
        
        @media screen and (max-width: 768px) {
            margin-bottom: 1.5rem; 
          }

        img {
          max-width: 80%; 
          min-width: 60%;
          height: auto; 
          border-radius: 25%;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
          margin: 0 auto;
        }
      }
    }

    a {
        text-decoration: none;
      }
  
    .about-me-right {
      flex: 1;
  
      p {
        font-size: 1.6vw;
        margin-bottom: 10px;

        @media screen and (max-width: 768px) {
          font-size: 1.4rem;
        }
      }
  
      .button-class {
        display: flex;
        flex-direction: row; 
        align-items: center; 
        width: auto;
        font-size: 1.2vw; 
        background-color: #5d94d1; 
        color: #ffffff; 
        font-weight: 500; 
        padding-top: 0.375rem; 
        padding-bottom: 0.375rem;  
        padding-left: 1rem; 
        padding-right: 0.5rem; 
        border-radius: 0.375rem; 
        margin-top: 1rem;
        box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.2);

        @media screen and (max-width: 768px) {
          font-size: 1.1rem;
        }
      
        .doc-svg {
          width: 25px;
          height: 25px;
          margin-left: 8px;
        }

        &:hover {
            background-color: rgb(9, 136, 215);
            color: #fff; 
          }
        
          &:active {
            box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.8);
          }
      }
    }
  }
  